<template>
  <!-- buyer dashboard my order page -->
  <div>
    <div>
      <TheTitleBar
        sectionTitle="dashboard.label.mnuOrders"
        sectionID="buyerOrders"
        :showCount="true"
        :showValue="false"
        :showFilters="true"
        :showSort="true"
        :showSellButton="false"
        :totalCount="totalVehicles"
        :totalValue="0"
        @qSortValue="sort"
        @qDateFilter="filterList"
      />
    </div>

    <div>
      <b-row class="p-3 justify-content-left" v-if="cardsData">
        <b-col
          cols="auto"
          class="my-3"
          v-for="carDetail in cardsData"
          :key="carDetail.l_ListingId"
        >
          <TheCarCard
            :carDetail="carDetail"
            :status="true"
            buttonType="confirm"
          ></TheCarCard>
        </b-col>
      </b-row>
      <b-container v-else>
        <h1 class="p-5 my-5 text-secondary text-center">
          {{ $t("dashboard.label.noResults") }}
        </h1>
      </b-container>
    </div>
  </div>
</template>

<script>
import TheTitleBar from "@/components/dashboard/global/TheTitleBar.vue";
import TheCarCard from "@/components/global/TheCarCard.vue";

export default {
  components: {
    TheTitleBar,
    TheCarCard
  },
  data() {
    return {
      sortBy: "Defaults",
      filterByDate: "Defaults",
      filterByStatus: "Defaults",
      dateFilter: null,
      statusFilter: null,
      sortOrder: 0,
      totalVehicles: 0
    };
  },
  computed: {
    cardsData() {
      const l = this.$store.getters.orderList.CardsData;
      return l ? l : [];
    }
  },
  methods: {
    sort(sortOrder) {
      let l = this.cardsData;
      if (sortOrder === 6) l.sort((a, b) => (a.l_Year < b.l_Year ? 1 : -1));
      if (sortOrder === 5) l.sort((a, b) => (a.l_Year > b.l_Year ? 1 : -1));
      if (sortOrder === 4)
        l.sort((a, b) =>
          Number(a.l_Odometer) < Number(b.l_Odometer) ? 1 : -1
        );
      if (sortOrder === 3)
        l.sort((a, b) =>
          Number(a.l_Odometer) > Number(b.l_Odometer) ? 1 : -1
        );
      if (sortOrder === 2)
        l.sort((a, b) =>
          JSON.parse(a.Prices)[0].Price < JSON.parse(b.Prices)[0].Price ? 1 : -1
        );
      if (sortOrder === 1)
        l.sort((a, b) =>
          JSON.parse(a.Prices)[0].Price > JSON.parse(b.Prices)[0].Price ? 1 : -1
        );
    },
    filterList(dateFilter, statusFilter) {
      let parameter = {
        lang: this.$i18n.locale,
        status: statusFilter,
        purchacedDate: dateFilter
      };
      this.$store.dispatch("getOrderList", parameter);
    },
    getTotal() {
      if (this.cardsData) this.totalVehicles = this.cardsData.length;
    }
  },
  updated() {
    this.getTotal();
  },
  beforeMount() {
    this.filterList();
    this.getTotal();
  }
};
</script>
